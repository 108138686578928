<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/Contact-TR-rev.1-210609-DEF-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">

                                        The TR series defines the new models with transfer structure of the range of
                                        automatic machines that allows wide possibilities of machine equipment and high
                                        yield.
                                    </p>
                                </div>
                                <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    TECHNICAL DATA
                                </h1>
                                <br />
                                <h3 style="color: black; font-weight: 800">Power supply</h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    3 x 400 VAC 50 / 60Hz / 3 x 230 VAC 50/60 Hz
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Pneumatic supply</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    6 - 7 bar
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">
                                    The maximum
                                </h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    power is 6 kW
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Sound level</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    69.3 dB (with 2 crimping units)
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Dimensions</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    L 3800 mm x P 2400 mm x H 1950 mm
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Weight</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    1000 kg
                                </p>

                                <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    PERFORMANCE
                                </h1>
                                <br />
                                <h3 style="color: black; font-weight: 800">Conductor</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    0.13 - 6 mm²
                                </p>
                                <br />
                                <h3 style="color: black; font-weight: 800">Weight</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    1000 kg
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Maximum cable feeding speed</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    8 m / s - belt feeding system
                                    maximum 12 m / s - roller feeding system
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Stripping length</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    0 - 15mm side 1 (optional 30mm) / 0 - 15mm side 2
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Cable</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    length minimum length 80 mm (optional 30 mm) / maximum length 99 m
                                </p>


                                <br />
                                <h3 style="color: black; font-weight: 800">Tolerance</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    ± 1 mm up to 3m; ± 0.2% from 3 to 99m / Resolution 0.1mm
                                </p>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    CQC Crimping control system
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Packaging external dimensions of
                                </h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    the basic machine W 1720 mm x D 3600 mm x H 1960 mm
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Transfer</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    L 1200 mm x P 1300 mm x H 1960 mm
                                </p>

                                <br />

                                <h3 style="color: black; font-weight: 800">Weight</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    700 kg
                                </p>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    With free cable without pre-power supply
                                </p>
                                <br />
                                <h3 style="color: black; font-weight: 800">OTHER ACCESSORIES AVAILABLE ARE</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    Inkjet marker implementation Dual device Crimping control systems
                                    Dual device Band cutter Split Cycle paper rewinder Specific cable processing Order
                                    List Stacker extension 2 meters. Ready for Industry 4.0 Tinning unit Fluxing unit
                                    Linking unit
                                </p>

                                <br />

                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    Data and measurements are indicative and subject to change
                                    without notice. Some particular types of cables may not be
                                    machined even though they fall within the range of sections
                                    indicated. KM Corporate will still be happy to carry out tests
                                    and supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br>
                        <br>
                        <br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800;margin-left:40px;" class="font-size-20 mb-3">
                                Gallery
                            </h1>
                        </div>
                        <!-- end row -->
                        <div class="row">
                            <div style="margin-left:50px;">
                                <vue-picture-swipe :items="items"></vue-picture-swipe>
                            </div>
                        </div>
                        <!-- end row -->
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {
        Header,

        Footer,
    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Contact-TR-rev.1-210614-DEF-ELENCO-150x150.png',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Contact-TR-rev.1-210614-DEF-ELENCO-150x150.png',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-TR-PIANTINA-rev.-3-210802-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-TR-PIANTINA-rev.-3-210802-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/08/Contact-TR-optional-piantina-rev.3-210802-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/Contact-TR-optional-piantina-rev.3-210802-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-041-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-041-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-037-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-037-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-025-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/CONTACT-TR-KM-025-150x150.jpg',
                w: 1200,
                h: 900
            },

            ]
        };

    }
};
</script>